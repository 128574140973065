<template>
    <div class="flex justify-center px-3.5 sm:px-5 lg:px-10">
        <div class="grid grid-cols-1 xl:grid-cols-2 max-w-page w-full gap-20">
            <div class="flex items-center justify-center">
                <div class="relative">
                <nuxt-img format="webp" loading="lazy"  src="/images/balona.png" class="absolute -top-[70%] left-0" width="466" height="551" alt="balona"/>
<!--                <nuxt-img format="webp"  src="/images/clouds.png" class="absolute top-0 left-0 max-w-[1153px] w-full max-h-[479px] h-full" width="1153" height="479"/>-->
                <nuxt-img format="webp" loading="lazy"  src="/images/office.webp" alt="Office" width="629" height="438" class="rounded-[30px] shadow-[0_0_30px_0] shadow-[#ACB3B8]"/>
                </div>
            </div>
            <div class="flex flex-col items-center gap-y-10">
<!--                <img alt="logo" src="/images/hima-logo.svg"/>-->
<!--                <nuxt-img format="webp"  src="/images/red-logo.png" alt="red-logo" width="140" height="56" class="max-w-[308px] w-full"/>-->
                <div class="max-w-[600px]">
<!--                    <div class="text-primary font-bold text-lg">Rreth Hima Trips</div>-->
                    <div class="text-primary text-[36px] sm:text-[50px] font-bold">
                        Mirë se erdhe në bord!
                    </div>
                    <p class="base-text sm:text-lg mt-5 sm:mt-0">
                        Në Hima Trips, ne e kuptojmë bukurinë e udhëtimit pa supriza të papritura. Opsionet tona janë të kuruara me kujdes, hotelet e zgjedhura me dorë nga agjentët tanë ekspertë të udhëtimit e bëjnë rezervimin të thjeshtë. Frymëzohuni lehtësisht sepse për ne, udhëtimi juaj është më shumë se një udhëtim; është një përvojë.
                    </p>
                    <p class="text-sm sm:text-lg mt-2">
                        “Asnjë surprizë e fshehur – vetëm emocioni i një aventure të re.”
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
